import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: 'https://www.chengwenit.com/img/logo/chwitlogo.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: 'https://ji.chengwenit.com',
          children: [{ children: '数据集成平台', name: 'text' }],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: 'https://yao.chengwenit.com',
          children: [{ children: '医药数字化', name: 'text' }],
        },
      },
      {
        name: 'item0',
        className: 'header0-item',
        children: {

          children: [{ children: '微信生态', name: 'text' }],
        },
        subItem: [
          {
            name: 'sub0',
            className: 'item-sub',
            
            children: {
              className: 'item-sub-item',
              href: '#Content3_0',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://www.chengwenit.com/img/icon/wxgz.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '微信公众平台',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '小程序 公众号开发',
                },
              ],
            },
          },
          {
            name: 'sub1',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://www.chengwenit.com/img/icon/wxpay.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '微信支付',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '费率低至0.2%',
                },
              ],
            },
          },
          {
            name: 'sub2',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://www.chengwenit.com/img/icon/comwx.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '企业微信',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '移动化打通企业内部应用',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: 'https://cloud.chengwenit.com',
          children: [{ children: '云+安全', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner1-title',
          children:
            'https://www.chengwenit.com/img/logo/chwlogow.png',
        },
        content: {
          className: 'banner1-content',
          children: '企业数字化全场景支持，竭诚为您提供优质IT服务',
        },
        button: { className: 'banner1-button', children: '联系我们',href: '#Footer1_0' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          className: 'banner1-title',
          children:
            'https://www.chengwenit.com/img/logo/chwlogow.png',
        },
        content: {
          className: 'banner1-content',
          children: '由内而外，全链数字化协同，打通企业数字化上下游',
        },
        button: { className: 'banner1-button', children: '联系我们',href: '#Footer1_0'},
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          className: 'banner1-title',
          children:
            'https://www.chengwenit.com/img/logo/chwlogow.png',
        },
        content: {
          className: 'banner1-content',
          children: '从0到1构建数据管理平台，助力企业数字化转型',
        },
        button: { className: 'banner1-button', children: '售前客服', href: 'https://work.weixin.qq.com/kfid/kfc709b03289dc308a5' },
      },
    ],
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '产品与服务' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://www.chengwenit.com/img/prod/dasj.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '集成调度平台',
            },
            { name: 'content', children: 'ERP/WMS/电商/开放银行/数字化发票,内外部系统集成' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://www.chengwenit.com/img/prod/sjksh.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '医药行业数字化转型',
            },
            {
              name: 'content',
              children: '全方位数字化运营管理，提升数据管理水平',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://www.chengwenit.com/img/prod/app.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '企业应用移动化开发',
            },
            {
              name: 'content',
              children: '构建移动化的TMS/SRM/CRM协同企业上下游',
            },
          ],
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '腾讯ToB全系列产品服务商',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '基于腾讯云强大的基础资源，提供从营销到交付、售后一站式企业移动数字化服务',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://www.chengwenit.com/img/icon/payservice.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '微信支付服务商' },
          content: {
            className: 'content3-content',
            children:
              '为企业自建应用提供微信支付对接和商户优惠费率申请以及商户分账支持',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://www.chengwenit.com/img/icon/wxservice.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '微信公众平台开发' },
          content: {
            className: 'content3-content',
            children:
              '提供公众号和小程序一站式定制开发服务，打通微信公众号小程序以及自建应用接入微信认证',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://www.chengwenit.com/img/icon/qywx.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '企业微信' },
          content: {
            className: 'content3-content',
            children:
              '开发企业微信小程序 集成企业内部系统 承载企业内外关系沟通 打造私域流量池',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://www.chengwenit.com/img/icon/tcloud.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '腾讯云' },
          content: {
            className: 'content3-content',
            children:
              '域名，虚拟主机，云服务器，云数据库，CDN加速，云存储，SAAS应用',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://www.chengwenit.com/img/icon/ad.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '效果广告' },
          content: {
            className: 'content3-content',
            children:
              '为企业提供基于用户标签和LBS 地址位置的广告投放精准触达',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://www.chengwenit.com/img/icon/email.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '企业邮箱' },
          content: {
            className: 'content3-content',
            children:
              '专业、安全的企业邮箱,赠送专属域名，对接企业内部系统发送邮件通知',
          },
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '客户案例', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: '专业的服务，始终如一',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/huajian.png',
          },
          content: { children: '华健药业' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/hygk.png',
          },
          content: { children: '环宇高科' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/dxx.png',
          },
          content: { children: '桂林东西巷' },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/bailin.png',
          },
          content: { children: '百林园林' },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/kaifeng.png',
          },
          content: { children: '凯丰物业' },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/bangjian.png',
          },
          content: { children: '邦健医药' },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/xuhui.png',
          },
          content: { children: '旭辉集团' },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/ynzzy.jpg',
          },
          content: { children: '中致远汽车' },
        },
      },
    ],
  },
};
export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      { name: 'title', className: 'feature8-title-h1', children: '服务流程' },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: '流程简单清晰，快速响应需求',
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: { href: '#', children: '联系我们' },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: '交付服务流程',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/diaoyan.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求调研',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '专业业务顾问，调研和分析需求，分析可行性',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/lantu.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '方案设计',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '接收需求，转化为设计文档，作为开发与业务人员直接的桥梁',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/shishi.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '实施开发',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '实施培训、开发编码、测试、部署上线',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/yunwei.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '上线维护',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '切替上线后进行维护，保障成功应用',
                  },
                ],
              },
            },
          ],
        },
        {
          name: 'block1',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            children: '运维服务流程',
            className: 'feature8-carousel-title-block',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/faqi.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '服务工单发起',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '由用户或者成文实施顾问发起服务请求',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/yixian.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '一线客服',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '一线客服人员服务台接单，处理基本操作和咨询问题',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/erxian.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '二线技术支持',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '技术问题处理，如系统配置、运行环境故障排查等，有技术支持人员排查和处理',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/sanxian.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '三线研发',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '技术支持无法解决的程序BUG，性能调优、架构建议有研发人员进行支持',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://www.chengwenit.com/img/logo/chwlogo.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: '企业简介' },
  content: {
    className: 'content1-content',
    children:
      ' 广州成文数字科技有限公司是一家专业的数字化服务商，成立于2017年，坐落于粤港澳大湾区广州南沙乐天云谷产业园。致力于帮助企业挖掘数据资产价值，从0到1构建数据管理平台。消除信息孤岛、提高运营效率，助力企业数字化转型，实现业务增长。',
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '推荐' },
        childWrapper: {
          children: [
            { name: 'link0', href: 'https://cloud.chengwenit.com/', children: '企业上云' },
            { name: 'link1', href: 'https://ji.chengwenit.com/', children: '调度平台' },
            { name: 'link2', href: '#', children: '移动化开发' },
            { name: 'link3', href: 'https://pms.chengwenit.com/', children: '收费云' },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '联系我们' },
        childWrapper: {
          children: [
            { href: 'https://p.qiao.baidu.com/cps/chat?siteId=18629363&userId=44582102&siteToken=741639e4d4bbf93fdb67e90c231e3a7a&cp=%E5%85%AC%E5%8F%B8%E5%AE%98%E7%BD%91%E9%A6%96%E9%A1%B5&cr=&cw=%E5%BA%95%E9%83%A8%E5%94%AE%E5%89%8D%E5%AE%A2%E6%9C%8D%E6%8C%89%E9%92%AE', name: 'link0', children: '售前客服' },
            { href: 'https://p.qiao.baidu.com/cps/chat?siteId=18629363&userId=44582102&siteToken=741639e4d4bbf93fdb67e90c231e3a7a&cp=%E5%85%AC%E5%8F%B8%E5%AE%98%E7%BD%91%E9%A6%96%E9%A1%B5&cr=&cw=%E5%BA%95%E9%83%A8%E7%94%B5%E8%AF%9D', name: 'link0', children: '18617332875' },
            { href: 'https://map.baidu.com/@12637936.037535219,2594314.883485,19z', name: 'link0', children: '广州市南沙区乐天云谷3栋105-13号' },
            { href: 'https://blog.chengwenit.com', name: 'link0', children: 'blog' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://www.chengwenit.com/img/logo/gzhm.jpg',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: '公众号',
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://www.chengwenit.com/img/logo/wxkf.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: '在线客服',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 广州成文数字科技有限公司 | 2023 &nbsp; &nbsp;
        <a href="http://beian.miit.gov.cn">粤ICP备17077704号</a> <br /> 
      </span>
    ),
  },
};
